import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import React from 'react';
import Measure, { ContentRect } from 'react-measure';
import { brandSecondary } from '../../../styles/colors';
import { RadioButton } from '../../RadioButton';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import { LightBulbIcon } from '../../svgs/icons/LightBulbIcon';
import { styleSheet } from './styles';
export const SocialMediaImagesPreviewAlert = () => {
	return (
		<div className={css(styleSheet.infoWrapper)}>
			<figure>
				<LightBulbIcon fill={brandSecondary} />
			</figure>
			<p>
				This is a preview of the logo overlay. The logo size is an approximation and may vary. The final image will be
				posted to the selected targets.
			</p>
		</div>
	);
};

export const SocialMediaImagesPreviewImages = ({
	images,
	currentImageIndex,
	showOverlayLogo,
	url,
	onImageIndexChange,
	hideWhiteBackground,
}: {
	images: Api.IFileAttachment[];
	currentImageIndex: number;
	showOverlayLogo: boolean;
	url: string;
	onImageIndexChange: (index: number) => () => void;
	hideWhiteBackground?: boolean;
}) => {
	const logoImageRef = React.useRef<HTMLImageElement>(null);
	const [imageSize, setImageSize] = React.useState({ width: 0, height: 0 });
	const [intrinsicSize, setIntrinsicSize] = React.useState({ width: 0, height: 0 });
	const [logoSize, setLogoSize] = React.useState<{ width: string | number; height: string | number }>({
		width: 'auto',
		height: 'auto',
	});
	const [logoPadding, setLogoPadding] = React.useState({ padding: '4px 16px' });
	const [logoLoaded, setLogoLoaded] = React.useState(false);

	const onContentResize = React.useCallback(
		(contentRect: ContentRect) => {
			setImageSize({ ...imageSize, width: contentRect.client.width, height: contentRect.client.height });
		},
		[imageSize]
	);
	const handleImageLoad = React.useCallback((event: React.SyntheticEvent<HTMLImageElement>) => {
		const { naturalWidth, naturalHeight } = event.currentTarget;
		setIntrinsicSize({ width: naturalWidth, height: naturalHeight });
	}, []);

	const handleLogoLoad = React.useCallback((event: React.SyntheticEvent<HTMLImageElement>) => {
		event.preventDefault();
		setLogoLoaded(true);
	}, []);

	React.useEffect(() => {
		if (!logoImageRef.current?.clientWidth || !logoImageRef.current?.clientHeight) return;

		if (intrinsicSize.width !== 0 && intrinsicSize.height !== 0 && logoLoaded) {
			// Calculate the scale of the rendered image to the intrinsic size
			const widthScale = imageSize.width / intrinsicSize.width;
			const heightScale = imageSize.height / intrinsicSize.height;

			const logoWidth = logoImageRef.current.clientWidth;
			const logoHeight = logoImageRef.current.clientHeight;

			// If the logo is wider or equal to the height, we want to scale the width and adjust the height accordingly
			// If the logo is taller, we want to scale the height and adjust the width accordingly
			const newLogoSize =
				logoWidth < logoHeight
					? { width: 'auto', height: Math.min(150, logoHeight) * heightScale }
					: { width: Math.min(150, logoWidth) * widthScale, height: 'auto' };

			setLogoSize(newLogoSize);
			setLogoPadding({ padding: `${4 * heightScale}px ${16 * widthScale}px` });
			setLogoLoaded(false);
		}
	}, [imageSize, intrinsicSize, logoLoaded]);

	return (
		<Measure client={true} innerRef={logoImageRef} onResize={onContentResize}>
			{({ measureRef }) => {
				return (
					<div className={css(styleSheet.container, images?.length === 1 ? styleSheet.containerCenter : undefined)}>
						{images?.length > 1 ? (
							<button onClick={onImageIndexChange(currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1)}>
								<DisclosureIcon fillColor={brandSecondary} type='chevron' className={css(styleSheet.pointLeft)} />
							</button>
						) : null}
						<figure className={css(styleSheet.imageContainer)}>
							<picture>
								<img
									ref={measureRef}
									src={images?.[currentImageIndex]?.url}
									alt={images?.[currentImageIndex]?.fileName}
									className={css(styleSheet.mainImage)}
									onLoad={handleImageLoad}
								/>
							</picture>

							{showOverlayLogo ? (
								<picture
									className={css(
										styleSheet.logoWrapper,
										hideWhiteBackground ? styleSheet.logoWrapperHideBackground : null
									)}
									style={logoPadding}
								>
									<img src={url} ref={logoImageRef} style={{ ...logoSize }} onLoad={handleLogoLoad} />
								</picture>
							) : null}
						</figure>
						{images?.length > 1 ? (
							<button onClick={onImageIndexChange(currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1)}>
								<DisclosureIcon fillColor={brandSecondary} type='chevron' className={css(styleSheet.pointRight)} />
							</button>
						) : null}
					</div>
				);
			}}
		</Measure>
	);
};

export const SocialMediaImagesPreviewRadioButtons = ({
	currentImageIndex,
	images,
	onImageIndexChange,
}: { currentImageIndex: number; images: Api.IFileAttachment[]; onImageIndexChange: (index: number) => () => void }) => {
	return (
		<div className={`${css(styleSheet.tabNavigation)}`}>
			{images.map((_, i) => {
				return (
					<RadioButton
						checked={i === currentImageIndex || false}
						className={css(styleSheet.circle)}
						id={`images-${i}`}
						key={`images-${i}`}
						onChange={onImageIndexChange(i)}
					/>
				);
			})}
		</div>
	);
};
